import React, { createContext, useContext, useState } from "react";
import orange_oilrig from "../Image/orange_oilrig.png";
import orange_oilrig_ru from "../Image/ru_images/orange_oilrig_ru.png";
import product_list from "../Image/product_list.svg";
import product_list_ru from "../Image/ru_images/product_list_ru.svg";

const languageToImagesMap = {
  en: [orange_oilrig, product_list],
  ru: [orange_oilrig_ru, product_list_ru],
  // Add other languages and images here
};

const LanguageImageContext = createContext();

export function useLanguageImage() {
  return useContext(LanguageImageContext);
}

export const LanguageImageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en"); // Default language
  const [images, setImages] = useState(languageToImagesMap[language]); // Default images array

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    setImages(languageToImagesMap[newLanguage]);
  };

  // Function to get a specific image by index
  const getImage = (index) => images[index] || images[0]; // Fallback to the first image if index is out of bounds

  return (
    <LanguageImageContext.Provider value={{ images, changeLanguage, getImage }}>
      {children}
    </LanguageImageContext.Provider>
  );
};
