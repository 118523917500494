import React from "react";
import { MapContainer, TileLayer, Marker, Popup, AttributionControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix an issue where the default marker is not displayed properly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const center = [43.253535, 76.902696]; // Coordinates for Almaty, Kazakhstan

const MapComponent = () => {
  return (
    <MapContainer
      center={center}
      zoom={13}
      style={{
        display: "block",
        margin: "auto",
        height: "400px",
        width: "80%",
      }}
      attributionControl={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='<a href="https://leafletjs.com/">Leaflet</a> | Data by &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under <a href="https://opendatacommons.org/licenses/odbl/">ODbL.</a>'  />
      <Marker position={center}>
        <Popup>KEA Hydraulic Company</Popup>
      </Marker>
      <AttributionControl position="bottomright" prefix={false} />
    </MapContainer>
  );
};

export default MapComponent;
