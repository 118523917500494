import {
    Flex,
    Image,
  } from "@chakra-ui/react";
  import logo from '../Image/logo.png'
  import Colors from "../constanst/Colors";
  import React from 'react';


  const Footer = () => {
  
  
    return (
      <Flex
        as="footer"
        width="full"
        align="center"
        justify="center"
        bg={Colors.charcoal}
        h='180px'
      >
          <Image
            src={logo}
            alt="KEA Logo"
            w='150px' h='150px'
            objectFit="contain"
          />
          
      </Flex>
    );
  };
  
  export default Footer;