import React from "react";
import { Box } from "@chakra-ui/react";
import { Element } from "react-scroll";
import Navbar from "./components/Navbar"; // Adjust the path as necessary
import HeroSection from "./components/HeroSection"; // Adjust the path as necessary
import AboutSection from "./components/AboutSection"; // Adjust the path as necessary
import ServicesSection from "./components/ServicesSection"; // Adjust the path as necessary
import ContactSection from "./components/ContactSection"; // Adjust the path as necessary
import Footer from "./components/Footer";
import { LanguageImageProvider } from "./components/LanguageImageContext";

function App() {
  return (
    <LanguageImageProvider>
      <Box minH="100vh" m="auto" overflow="hidden">
        <Navbar />
        <Element name="hero">
          <HeroSection />
        </Element>
        <Element name="about">
          <AboutSection />
        </Element>
        <Element name="services">
          <ServicesSection />
        </Element>
        <Element name="contact">
          <ContactSection />
        </Element>
        <Footer />
      </Box>
    </LanguageImageProvider>
  );
}

export default App;
