import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: {
        translation: {
          Navbar:
            "Deliveries with complex logistics and consolidated procurement to the Far Eastern regions",
          "Navbar home": "Home",
          "Navbar services": "Services",
          "Navbar about": "About Us",
          "Navbar contact": "Contact",

          "Hero greeting": "COMPREHENSIVE SUPPLY FOR ENTERPRISES IN THE OIL AND GAS SECTOR",
          "Point 1": "1. A wide selection of products and competitive prices",
          "Point 2": "2. We work directly with manufacturers.",
          "Point 3": "3. Only certified products.",
          "Point 4": "4. Fast product delivery.",
          

          About: "About Us",
          "About 1": "Highly qualified personnel",
          "About 1.1":
            "Our experts will promptly process your application and assist in selecting the right products",
          "About 2": "Individual approach to each client",
          "About 2.1":
            "We offer special conditions for regular customers, including a flexible discount system and deferred payment",
          "About 3": "Own warehouses",
          "About 3.1":
            "A large assortment of products in stock for the fastest possible shipment",
          "About 4": "Reliable suppliers",
          "About 4.1":
            "We work directly with manufacturers and have partnership and dealership agreements with suppliers who have proven themselves in the market",
          "About 5": "Our regular partners",
          "About 5.1":
            "are leading manufacturers from Russia, Europe, the USA, China, and South Korea",
          "About 6": "Own warehouses and shipment 24/7",
          "About 6.1":
            "popular items are consolidated in our warehouses for the fastest possible dispatch",
          "About 7": "Personal client manager accompanies your order",
          "About 7.1":
            "at all stages of procurement, shipment, and delivery without shifting responsibility",
          "About 8": "Established connections with customs brokers",
          "About 8.1":
            "allow for minimizing costs during the customs clearance stage",
          "About 9": "We collaborate with industry leaders",
          "About 9.1":
            "helping major companies to supply facilities in the oil and gas sector in the Far East",
          "About 10": "Own fleet and partnership agreements",
          "About 10.1": "with all leading logistics operators",
          More: "More than",
          Suplliers: "suppliers, proven over time",
          Saving: "savings with a comprehensive order",
          Steps: "STEPS OF SUPPLIER VERIFICATION",
          Protect:
            "you are fully protected against low-quality products and fly-by-night companies",

          Services: "Services",
          Approach: "COMPREHENSIVE APPROACH IN SUPPLY",
          "Step 1": "purchase \n item",
          "Step 2": "customs clearance",
          "Step 3": "delivery to the location",
          Our: "Our specialization",
          "Our 1.1":
            "is the supply of a wide range of goods for the provisioning of objects in the oil and gas industry",
          Documents: "The complete package of documents",
          "Documents 1.1":
            "including VAT, certificates, and passports for all products, from suppliers ourselves",
          "Product List": "Product List",

          Contact: "Contact",
          "Save time":
            "SAVE TIME — SEND YOUR PROCUREMENT LIST FOR OUR MANAGERS TO CALCULATE.",
          Company: "Company",
          "Company name": "KEA Hydraulic Company",
          email: "Email",
          "Phone number": "Phone Number",
          Address: "Address",
          "Address 1": "Auezov St 14А, Almaty 050026, Kazakhstan",

        },
      },
      ru: {
        translation: {
          Navbar:
            "Поставки со сложной логистической и сборной закупкой в дальневосточные регионы",
          "Navbar home": "Главная",
          "Navbar services": "Услуги",
          "Navbar about": "О Нас",
          "Navbar contact": "Контакты",

          "Hero greeting": "КОМПЛЕКСНОЕ СНАБЖЕНИЕ ПРЕДПРИЯТИЙ НЕФТЕГАЗОВОГО СЕКТОРА",
          "Point 1": "1. Широкий выбор товаров и конкуртные цены",
          "Point 2": "2. Работаем на прямую с производителями",
          "Point 3": "3. Только сертифицированный товар",
          "Point 4": "4. Быстрая доставка товара",

          About: "О нас",
          "About 1": "Высококвалифицированные кадры",
          "About 1.1":
            "Наши специалисты оперативно обработают вашу заявку и помогут в подборе нужной продукции",
          "About 2": "Индивидуальный подход к клиенту",
          "About 2.1":
            "Мы предлагаем особые условия постоянным клиентам, включающие гибкую систему скидок и отсрочку платежа",
          "About 3": "Собственные склады",
          "About 3.1": "Большой ассортимент продукции на складе для максимально быстрой отгрузки",
          "About 4": "Надежные поставщики",
          "About 4.1":
            "Работаем на прямую с заводами-производителями, имеем партнерские и дилерские договора с зарекомендовавшими себя на рынке поставщиками",
          "About 5": "Наши постоянные партнеры",
          "About 5.1":
            " ведущие производители России, Европы, США, Китая, Южной Кореи",
          "About 6": "Собственные склады и отгрузка 24/7",
          "About 6.1":
            "популярные позиции мы консолидируем на своих складах для максимально быстрой отгрузки",
          "About 7": "Персональный клиентский менеджер",
          "About 7.1":
            "сопровождает ваш заказ на всех этапах закупки, отгрузки и доставки без перекладывания ответственности",
          "About 8": "Налаженные связи с таможенными брокерами",
          "About 8.1":
            "позволяют минимизировать издержки на этапе таможенной очистки",
          "About 9": "Сотрудничаем с лидерами отрасли",
          "About 9.1":
            "помогаем крупнейшим компаниям снабжать объекты нефтегазовой отрасли на Дальнем Востоке",
          "About 10": " Собственный автопарк и партнерские договоры",
          "About 10.1": "со всеми ведущими логистическими операторами",
          More: "Более",
          Suplliers: "поставщиков, проверенных временем",
          Saving: "экономия при комплексном заказе",
          Steps: "СТУПЕНЕЙ ПРОВЕРКИ ПОСТАВЩИКОВ",
          Protect:
            "вы полностью защищены от некачественной продукции и фирм-однодневок",

          Services: "Услуги",
          Approach: "КОМПЛЕКСНЫЙ ПОДХОД В СНАБЖЕНИИ",
          "Step 1": "закупка позиций",
          "Step 2": "таможенная очистка",
          "Step 3": "доставка на локацию",
          Our: "Наша специализация",
          "Our 1.1":
            "поставки широкого спектра товаров для снабжения объектов нефтегазовой отрасли",
          Documents: "Полный пакет документов",
          "Documents 1.1":
            "НДС, сертификаты и паспорта на всю продукцию мы сами запрашиваем и проверяем у поставщиков",

          Contact: "Контакты",
          "Save time":
            "СЭКОНОМЬТЕ ВРЕМЯ — ОТПРАВЛЯЙТЕ СПИСОК ЗАКУПКИ ДЛЯ РАСЧЕТА НАШИМ МЕНЕДЖЕРАМ.",
          Company: "Компания",
          "Company name": "КЕА Гидравлик Компани",
          email: "Электроная почта",
          "Phone number": "Номер телефона",
          Address: "Адрес",
          "Address 1":
            "ул. Ауэзова, д. 14А, 5-й этаж, г. Алматы, Республика Казахстан",
        },
      },
    },
    lng: "en",
    fallbackLng: "en",
    react: {
      useSuspense: false,
    },
  });

export default i18n;
