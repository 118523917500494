const Colors = {
  charcoal: "#383F51",
  lavender: "#DDDBF1",
  blue: "#3C4F76",
  dun: "#DIBEBO",
  rose: "#AB9F9D",
  darkrose: "#941C2F",
  lightred: "#FD3A3A",
  lightrose: "#EBE0E9",
  white: "#FFFFFF",
};

export default Colors;
