import React from "react";
import { Image, Box } from "@chakra-ui/react";
import { useLanguageImage } from "./LanguageImageContext";

const ProblemsSection = () => {
  // Replace with the path to your actual image
  const { getImage } = useLanguageImage();
  const backgroundImage = getImage(0);

  return (
    <Box width="full" justify={"center"} align={"center"} mt={"60px"}>
      <Image src={backgroundImage} w={"100%"} />
    </Box>
  );
};

export default ProblemsSection;
