import { Flex, Heading, Box, Image, useBreakpointValue, SimpleGrid } from "@chakra-ui/react";
import image from '../Image/herosection.png'
import Colors from "../constanst/Colors";
import { useTranslation } from "react-i18next"



const HeroSection = () => {
  const { t } = useTranslation()
  const isMobile = useBreakpointValue({ base: true, lg: false });


  return (
    <Flex
      direction="column"
      align="center"
      color="white"
      p={5}
      bg={Colors.charcoal}
      w={"full"}
    >
      <Flex align="center">
        <Heading as="h1" w={{ base: '270px', lg: '492px' }} marginRight='50px' fontSize={{ base: '30px', lg: '40px' }} fontFamily="Futura, sans-serif" color={Colors.lavender}>
          {t('Hero greeting')}
        </Heading>
        {!isMobile && (
          <Image src={image}
            alt="Hero Section"
            objectFit="contain"
            />
        )}
      </Flex>


      <SimpleGrid align={"center"} p='50px' justifyItems="center" spacing={10} columns={{ base: 1, md: 4 }} w={'full'}>
        <Box textAlign="left"  w='310px'>
          <Heading fontSize="22px" fontFamily="Futura, sans-serif" color={Colors.lavender}>
            {t('Point 1')}
          </Heading>
        </Box>
        <Box textAlign="left" w='310px' >
          <Heading fontSize="22px" fontFamily="Futura, sans-serif" color={Colors.lavender}>
          {t('Point 2')}
          </Heading>
        </Box >
        <Box textAlign="left" w='310px'>
          <Heading fontSize="22px" fontFamily="Futura, sans-serif" color={Colors.lavender}>
          {t('Point 3')}
          </Heading>
        </Box>
        <Box textAlign="left" w='310px'>
          <Heading fontSize="22px" fontFamily="Futura, sans-serif" color={Colors.lavender}>
          {t('Point 4')}
          </Heading>
        </Box>
      </SimpleGrid>

    </Flex>
  );
};

export default HeroSection;
