import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Circle,
  Image,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Colors from "../constanst/Colors";
import { useLanguageImage } from "./LanguageImageContext";
import ProblemsSection from "./ProblemsSection";

const ServicesComponent = ({
  ctaBasis = { base: "100%", lg: "30%" },
  serviceBasis = { base: "100%", lg: "40%" },
  additionalContentBasis = { base: "100%", lg: "30%" },
  spacing = { base: 4, lg: 10 },
}) => {
  const { getImage } = useLanguageImage();
  const backgroundImage = getImage(1);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { t } = useTranslation();

  return (
    // <Container maxW="container.xl" py={10}>
    <Flex
      direction={{ base: "column", md: "column" }}
      justify="space-between"
      align="center"
      w={"100%"}
    >
      <Box flexBasis={serviceBasis} align="start" spacing={2} mb={spacing}>
        <Text fontSize="3xl" fontWeight="bold" fontFamily="Futura, sans-serif" color={Colors.charcoal}>
          {t("Services")}
        </Text>
      </Box>

      {/* Left Side: Specialization and Documents */}
      <VStack spacing={5} flex="1" w={"80%"}>
        <HStack
          spacing={8}
          mt={10}
          align="center"
          justify={"center"}
          wrap={"wrap"}
          
        >
          <Heading size="md" mb={2} fontFamily="Futura, sans-serif" color={Colors.charcoal} w={'250px'}>
            {t("Approach")}
          </Heading>
          <ServiceCircle
            number="1"
            text={t("Step 1")}
            textColor={Colors.white}
            color={Colors.lightred}
          />
          <ServiceCircle
            number="2"
            text={t("Step 2")}
            textColor={Colors.white}
            color={Colors.charcoal}
          />
          <ServiceCircle
            number="3"
            text={t("Step 3")}
            textColor={Colors.darkrose}
            color={Colors.lightrose}
          />
        </HStack>
        <Flex
          p={5}
          align={"center"}
          gap={5}
          direction={{ base: "column", lg: "row", xl: "row" }}
        >
          <VStack align={"start"} spacing={5}>
            <Heading size="lg" mb={2} fontFamily="Futura, sans-serif" color={Colors.charcoal}>
              {t("Our")}
            </Heading>
            <Text fontFamily="Futura, sans-serif" color={Colors.charcoal}>{t("Our 1.1")}</Text>
            <Divider borderColor={Colors.charcoal} />
            <Heading size="lg" mb={2} fontFamily="Futura, sans-serif" color={Colors.charcoal}>
              {t("Documents")}
            </Heading>
            <Text fontFamily="Futura, sans-serif" color={Colors.charcoal}>{t("Documents 1.1")}</Text>
          </VStack>
          {!isMobile && (
            <Image src={backgroundImage} width='70%'></Image>
          )}
        </Flex>
        {!isMobile && (
        <ProblemsSection />
        )}
      </VStack>
      {isMobile && (
            <Image src={backgroundImage} width='90%'></Image>
          )}
      {isMobile && (
      <ProblemsSection />
      )}
    </Flex>
    // </Container>
  );
};

// Service Circle Component
const ServiceCircle = ({ number, text, color, textColor }) => {
  return (
    <VStack>
      <Circle size="180px" bg={color} padding={5}>
        <Flex justifyItems={"center"} alignItems={"center"} gap={2}>
          <Heading size="2xl" color={textColor} fontFamily="Futura, sans-serif">
            {number}
          </Heading>
          <Text textAlign="start" fontWeight="bold" color={textColor} fontFamily="Futura, sans-serif">
            {text}
          </Text>
        </Flex>
      </Circle>
    </VStack>
  );
};

export default ServicesComponent;

// TYOMA IM HERE
// justifyItems - horizontal
// justifyItems - vertical
