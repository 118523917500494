import { Flex, Box, Text } from "@chakra-ui/react";
import Colors from "../constanst/Colors";
import { useTranslation } from 'react-i18next';
import MapComponent from "./MapComponent";


const ContactSection = ({
  ctaBasis = { base: "100%", lg: "30%" },
  contactBasis = { base: "100%", lg: "40%" },
  additionalContentBasis = { base: "100%", lg: "30%" },
  spacing = { base: 4, lg: 10 },
}) => {
  const { t } = useTranslation();


  return (
    <Flex
      direction={{ base: "column", lg: "column" }}
      justify="center"
      align="center"
      p={10}
      bg={Colors.dun}
      wrap="wrap"
      fontFamily="Futura, sans-serif"
      w={"full"}
    >
      <Box flexBasis={contactBasis} align="start" spacing={2} mb={spacing}>
        <Text fontSize="3xl" fontWeight="bold" color={Colors.charcoal}>
          {t("Contact")}
        </Text>
      </Box>
      <Flex
        direction={{ base: "column", lg: "row", xl: "row" }}
        justify="space-between"
        maxW={"1240px"}
      >
        <Box flexBasis={ctaBasis} mb={spacing}>
          <Text color={Colors.darkrose} fontWeight="bold" fontSize="lg" mb={4}>
            {t("Save time")}
          </Text>
        </Box>
        <Box>
          <Text pb={7} color={Colors.charcoal}>
            {t("Company")}: <br />
            {t("Company name")}
          </Text>
          <Text color={Colors.charcoal}>
            {t("Phone number")}: <br />
            +7 (707) 495-73-03
          </Text>
        </Box>
        <Box>
          <Text pb={7} fontFamily="Futura, sans-serif" color={Colors.charcoal}>
            {t("email")}: <br />
            info@kea-hydraulic.com
          </Text>
          <Text color={Colors.charcoal}>
            {t("Address")}: <br />
            {t("Address 1")}
          </Text>
        </Box>
      </Flex>
      <Box flexBasis={additionalContentBasis} mb={spacing}>
      </Box>
      <MapComponent />
    </Flex>
  );
};

export default ContactSection;
