import { Text, useBreakpointValue, Box, Flex, SimpleGrid, Heading, Image, VStack, Divider } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import Colors from "../constanst/Colors";
import img from '../Image/aboutsection.png'


const AboutSection = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false, xl: false });

  return (
    <Flex
      direction={{ base: "column", lg: "column" }}
      justify="center"
      align="center"
      p={10}
      bgGradient='linear(to-b, #383F51 70%, white)'
      wrap="wrap"
      color={Colors.lavender}
      fontFamily="Futura, sans-serif"
    >
      <Text fontSize="3xl" fontWeight="bold" >
        {t("About")}
      </Text>
      <SimpleGrid p='10px' spacing={10} columns={{ base: 1, lg: 4, md: 3, }} w={'full'} justifyItems="center">
              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h3' size='md'> {t("About 1")} </Heading>
                <Text mt={2}>{t("About 1.1")}</Text>
              </Box>

              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h3' size='md'> {t("About 2")} </Heading>
                <Text mt={2}>{t("About 2.1")}</Text>
              </Box>

              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h3' size='md'> {t("About 3")} </Heading>
                <Text mt={2}>{t("About 3.1")}</Text>
              </Box>

              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h3' size='md'> {t("About 4")} </Heading>
                <Text mt={2}>{t("About 4.1")}</Text>
              </Box>

      </SimpleGrid>
      <Flex w="90%" mt={10} >
        <Flex p={5} bg={Colors.lavender} color={Colors.blue} justify={'space-between'} wrap="wrap" w={{ base: '100%', lg: "50%", xl: "50%" }}>
          <VStack spacing={4} align={'start'} w={'170px'}>
            <Divider bg={Colors.charcoal} />
            <Text fontSize="lg" fontWeight="bold">{t("More")}</Text>
            <Heading fontSize="4xl" fontWeight="bold" color={"#941C2F"}>200</Heading>
            <Text fontSize="lg" fontWeight="bold">{t("Suplliers")}</Text>
          </VStack>
          <VStack spacing={4} align={'start'} w={'170px'}>
            <Divider bg={Colors.charcoal} />
            <Text fontSize="lg" fontWeight="bold">{t("More")}</Text>
            <Heading fontSize="4xl" fontWeight="bold" color={"#941C2F"}>15%</Heading>
            <Text fontSize="lg" fontWeight="bold">{t("Saving")}</Text>
          </VStack>
          <Flex spacing={2} w={'400px'} mt={5} direction={{ base: 'column', lg: 'row', xl: 'row' }} gap={2} align={'start'}>
            <Heading fontSize={{ base: "3xl", lg: "4xl", xl: "4xl" }} fontWeight="bold" color={"#941C2F"} textAlign="center">10</Heading>
            <Text fontSize="xl" fontWeight="bold" color={"#941C2F"}>
              {t("Steps")}
            </Text>
          </Flex>
          <Text w={'400px'} fontSize="md" fontWeight="bold" mt={3}>{t("Protect")}</Text>
        </Flex>
        {!isMobile && (
          <Box
            width="50%"  // This will take the full width of the parent container
            position="relative"
          >
            <Image
              src={img}
              alt="About img"
              objectFit="cover"
              objectPosition="center"  // Adjust as needed to focus on a particular part of the image
              width="100%"
              height="100%"
            />
          </Box>
        )}

      </Flex>
      <SimpleGrid p='10px' mt={10} spacing={10} columns={{ base: 1, lg: 4, md: 3, }} w={'full'} justifyItems="center">
              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h3' size='md'> {t("About 6")} </Heading>
                <Text mt={2}>{t("About 6.1")}</Text>
              </Box>

              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h2' fontWeight="bold" size='md'> {t("About 7")} </Heading>
                <Text mt={2}>{t("About 7.1")}</Text>
              </Box>

              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h2' size='md'> {t("About 8")} </Heading>
                <Text mt={2}>{t("About 8.1")}</Text>
              </Box>

              <Box w={"250px"}  p={4} color={Colors.lavender} bg={Colors.charcoal} borderRadius={7}>
                <Heading as='h3' size='md'> {t("About 10")} </Heading>
                <Text mt={2}>{t("About 10.1")}</Text>
              </Box>

      </SimpleGrid>
    </Flex>
  );
};

export default AboutSection;
