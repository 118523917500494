import {
  Flex,
  Button,
  useBreakpointValue,
  Stack,
  Image,
  Select,
} from "@chakra-ui/react";
import logo from "../Image/logo.png";
import Colors from "../constanst/Colors";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { Link } from "react-scroll";
import { useLanguageImage } from "../components/LanguageImageContext";

const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const { t, i18n } = useTranslation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { changeLanguage } = useLanguageImage();

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    changeLanguage(newLanguage);
  };

  return (
    <Flex
      as="header"
      width="full"
      align="center"
      justify="space-between"
      padding={4}
      bg={Colors.charcoal}
      boxShadow="sm"
      position="top"
      zIndex="1000"
      h="180px"
      pr="5%"
    >
      <Flex align="center" p={{ base: "20px", lg: "80px" }} h="180px">
        <Image
          src={logo}
          alt="KEA Logo"
          w="150px"
          h="150px"
          objectFit="contain"
        />
        
      </Flex>

      {isDesktop ? (
        <Stack direction="row" spacing={4}>
          <Link to="hero">
            <Button
              variant="ghost"
              fontFamily="Futura, sans-serif"
              color={Colors.lavender}
            >
              {t("Navbar home")}
            </Button>
          </Link>
          <Link to="about">
            <Button
              variant="ghost"
              fontFamily="Futura, sans-serif"
              color={Colors.lavender}
            >
              {t("Navbar about")}
            </Button>
          </Link>
          <Link to="services">
            <Button
              variant="ghost"
              fontFamily="Futura, sans-serif"
              color={Colors.lavender}
            >
              {t("Navbar services")}
            </Button>
          </Link>
          <Link to="contact">
            <Button
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
              bg={Colors.lavender}
            >
              {t("Navbar contact")}
            </Button>
          </Link>
          <Select
            bg={Colors.lavender}
            w="80px"
            onChange={handleLanguageChange}
            defaultValue={i18n.language}
            fontFamily="Futura, sans-serif"
            color={Colors.charcoal}
          >
            <option
              value="en"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              EN
            </option>
            <option
              value="ru"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              РУ
            </option>
          </Select>
        </Stack>
      ) : (
        // Add a menu button for mobile here
        <Button
          variant="outline"
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          bg={Colors.lavender}
          color={Colors.charcoal}
        >
          {isMobileMenuOpen ? <ChevronUpIcon /> : "Menu"}
        </Button>
      )}
      {!isDesktop && isMobileMenuOpen && (
        <Stack
          direction="column"
          mt="80px"
          justify="center"
          align="center"
          bg={Colors.lavender}
          borderRadius="0.5rem"
        >
          <Link to="hero">
            <Button
              variant="ghost"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              {t("Navbar home")}
            </Button>
          </Link>
          <Link to="about">
            <Button
              variant="ghost"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              {t("Navbar about")}
            </Button>
          </Link>
          <Link to="services">
            <Button
              variant="ghost"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              {t("Navbar services")}
            </Button>
          </Link>
          <Link to="contact">
            <Button
              variant="ghost"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              {t("Navbar contact")}
            </Button>
          </Link>
          <Select
            bg={Colors.lavender}
            w="80px"
            onChange={handleLanguageChange}
            defaultValue={i18n.language}
            fontFamily="Futura, sans-serif"
            color={Colors.charcoal}
          >
            <option
              value="en"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              EN
            </option>
            <option
              value="ru"
              fontFamily="Futura, sans-serif"
              color={Colors.charcoal}
            >
              RU
            </option>
          </Select>
        </Stack>
      )}
    </Flex>
  );
};

export default Navbar;
